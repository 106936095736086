<template>
    <Page
        title="About"
        icon="mdi-help-circle-outline"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        This is an about page
        <btn @click="testAcrossModules({ test: true })" small>Click</btn>
    </Page>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            isShowError: false,
            errorMessage: '',
            isShowLoading: false,
            loadingMessage: '',
        };
    },
    methods: {
        ...mapActions(['testAcrossModules']),
    },
    computed: {
        ...mapGetters(['sid']),
    },
};
</script>
